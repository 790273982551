import _Slider2 from "element-ui/lib/theme-chalk/slider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Slider from "element-ui/lib/slider";
import _Avatar2 from "element-ui/lib/theme-chalk/avatar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Avatar from "element-ui/lib/avatar";
import _SkeletonItem2 from "element-ui/lib/theme-chalk/skeleton-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _SkeletonItem from "element-ui/lib/skeleton-item";
import _Skeleton2 from "element-ui/lib/theme-chalk/skeleton.css";
import "element-ui/lib/theme-chalk/base.css";
import _Skeleton from "element-ui/lib/skeleton";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _Calendar2 from "element-ui/lib/theme-chalk/calendar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Calendar from "element-ui/lib/calendar";
import _Image2 from "element-ui/lib/theme-chalk/image.css";
import "element-ui/lib/theme-chalk/base.css";
import _Image from "element-ui/lib/image";
import _Divider2 from "element-ui/lib/theme-chalk/divider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Divider from "element-ui/lib/divider";
import _Footer2 from "element-ui/lib/theme-chalk/footer.css";
import "element-ui/lib/theme-chalk/base.css";
import _Footer from "element-ui/lib/footer";
import _Main2 from "element-ui/lib/theme-chalk/main.css";
import "element-ui/lib/theme-chalk/base.css";
import _Main from "element-ui/lib/main";
import _Aside2 from "element-ui/lib/theme-chalk/aside.css";
import "element-ui/lib/theme-chalk/base.css";
import _Aside from "element-ui/lib/aside";
import _Header2 from "element-ui/lib/theme-chalk/header.css";
import "element-ui/lib/theme-chalk/base.css";
import _Header from "element-ui/lib/header";
import _Container2 from "element-ui/lib/theme-chalk/container.css";
import "element-ui/lib/theme-chalk/base.css";
import _Container from "element-ui/lib/container";
import _Cascader2 from "element-ui/lib/theme-chalk/cascader.css";
import "element-ui/lib/theme-chalk/base.css";
import _Cascader from "element-ui/lib/cascader";
import _CollapseItem2 from "element-ui/lib/theme-chalk/collapse-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CollapseItem from "element-ui/lib/collapse-item";
import _Collapse2 from "element-ui/lib/theme-chalk/collapse.css";
import "element-ui/lib/theme-chalk/base.css";
import _Collapse from "element-ui/lib/collapse";
import _Card2 from "element-ui/lib/theme-chalk/card.css";
import "element-ui/lib/theme-chalk/base.css";
import _Card from "element-ui/lib/card";
import _Badge2 from "element-ui/lib/theme-chalk/badge.css";
import "element-ui/lib/theme-chalk/base.css";
import _Badge from "element-ui/lib/badge";
import _Upload2 from "element-ui/lib/theme-chalk/upload.css";
import "element-ui/lib/theme-chalk/base.css";
import _Upload from "element-ui/lib/upload";
import _Col2 from "element-ui/lib/theme-chalk/col.css";
import "element-ui/lib/theme-chalk/base.css";
import _Col from "element-ui/lib/col";
import _Row2 from "element-ui/lib/theme-chalk/row.css";
import "element-ui/lib/theme-chalk/base.css";
import _Row from "element-ui/lib/row";
import _Icon2 from "element-ui/lib/theme-chalk/icon.css";
import "element-ui/lib/theme-chalk/base.css";
import _Icon from "element-ui/lib/icon";
import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _Popover2 from "element-ui/lib/theme-chalk/popover.css";
import "element-ui/lib/theme-chalk/base.css";
import _Popover from "element-ui/lib/popover";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _ButtonGroup2 from "element-ui/lib/theme-chalk/button-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _ButtonGroup from "element-ui/lib/button-group";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";
import _OptionGroup2 from "element-ui/lib/theme-chalk/option-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _OptionGroup from "element-ui/lib/option-group";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _CheckboxGroup2 from "element-ui/lib/theme-chalk/checkbox-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _CheckboxGroup from "element-ui/lib/checkbox-group";
import _CheckboxButton2 from "element-ui/lib/theme-chalk/checkbox-button.css";
import "element-ui/lib/theme-chalk/base.css";
import _CheckboxButton from "element-ui/lib/checkbox-button";
import _Checkbox2 from "element-ui/lib/theme-chalk/checkbox.css";
import "element-ui/lib/theme-chalk/base.css";
import _Checkbox from "element-ui/lib/checkbox";
import _RadioButton2 from "element-ui/lib/theme-chalk/radio-button.css";
import "element-ui/lib/theme-chalk/base.css";
import _RadioButton from "element-ui/lib/radio-button";
import _RadioGroup2 from "element-ui/lib/theme-chalk/radio-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _RadioGroup from "element-ui/lib/radio-group";
import _Radio2 from "element-ui/lib/theme-chalk/radio.css";
import "element-ui/lib/theme-chalk/base.css";
import _Radio from "element-ui/lib/radio";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _Menu2 from "element-ui/lib/theme-chalk/menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Menu from "element-ui/lib/menu";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import "/builds/ThemaOptical/nuovo-osix-configurator/node_modules/core-js/modules/es.array.iterator.js";
import "/builds/ThemaOptical/nuovo-osix-configurator/node_modules/core-js/modules/es.promise.js";
import "/builds/ThemaOptical/nuovo-osix-configurator/node_modules/core-js/modules/es.object.assign.js";
import "/builds/ThemaOptical/nuovo-osix-configurator/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// eslint-disable-next-line no-unused-vars
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import "./theme/index.css";
import "./theme/custom.less"; // import CanvasPlugin from 'configurator-base-app'
// @ts-ignore

import CanvasPlugin from 'configurator-base-app';
import Cookies from 'vue-cookies';
import VueI18n from 'vue-i18n'; // import this.$rest from 'configurator-base-app/src/plugins/ajax_request'

import './registerServiceWorker'; // Configurazione configurator-base-app

var settings = {
  VUE_APP_REST_URL: "".concat(process.env.VUE_APP_REST_URL),
  VUE_APP_CONFIG_IDENTIFIER: "".concat(process.env.VUE_APP_CONFIG_IDENTIFIER),
  // VUE_APP_CONFIG_IDENTIFIER: 'MYCLARO',
  // VUE_APP_CONFIG_ESCLUSIVA: `${process.env.VUE_APP_CONFIG_ESCLUSIVA}`,
  VUE_APP_CONFIG_ESCLUSIVA: 'OSIX',
  // VUE_APP_CONFIG_ESCLUSIVA: 'MYCLARO',
  VUE_APP_COLORS_URL: "".concat(process.env.VUE_APP_COLORS_URL),
  VUE_APP_MODEL_URL: "".concat(process.env.VUE_APP_MODEL_URL),
  VUE_APP_CONFIG_DATABASE: "".concat(process.env.VUE_APP_CONFIG_DATABASE),
  VUE_APP_MULTILINETEMPLES: false,
  VUE_APP_GTAG_ID: "".concat(process.env.VUE_APP_GTAG_ID)
}; // @ts-ignore

Vue.use(CanvasPlugin, {
  store: store,
  settings: settings
}); // Vue.use(Element, { locale });

Vue.use(_Pagination);
Vue.use(_Dialog);
Vue.use(_Menu);
Vue.use(_Input);
Vue.use(_Radio);
Vue.use(_RadioGroup);
Vue.use(_RadioButton);
Vue.use(_Checkbox);
Vue.use(_CheckboxButton);
Vue.use(_CheckboxGroup);
Vue.use(_Select);
Vue.use(_Option);
Vue.use(_OptionGroup);
Vue.use(_Button);
Vue.use(_ButtonGroup);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_DatePicker);
Vue.use(_Popover);
Vue.use(_Tooltip);
Vue.use(_Icon);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_Upload);
Vue.use(_Badge);
Vue.use(_Card);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_Cascader);
Vue.use(_Container);
Vue.use(_Header);
Vue.use(_Aside);
Vue.use(_Main);
Vue.use(_Footer); // Vue.use(Link);

Vue.use(_Divider);
Vue.use(_Image);
Vue.use(_Calendar);
Vue.use(_Loading.directive);
Vue.use(_Form);
Vue.use(_FormItem);
Vue.use(_Skeleton);
Vue.use(_SkeletonItem);
Vue.use(_Avatar);
Vue.use(_Slider);
Vue.use(VueI18n);
Vue.use(Cookies); // // eslint-disable-next-line no-unused-vars

Vue.filter('capitalize', function (s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
});
Vue.filter('upperCase', function (s) {
  if (typeof s !== 'string') return '';
  return s.toUpperCase();
});
Vue.filter('finishingLabel', function (f) {
  return f == "S" ? "Lucido" : "Opaco";
});
Vue.filter('asDate', function (d) {
  var date = d.split(" ")[0];
  return date.split("-").reverse().join("/");
});
Vue.filter('decodeLensModel', function (l) {
  if (typeof l !== 'string') {
    return '';
  } else {
    if (l.includes('1.50SF-UC')) {
      return 'MONOFOCALE 1.50 SFERICA-UC';
    } else if (l.includes('1.56SF-HMC')) {
      return 'MONOFOCALE 1.56 SFERICA-HMC';
    } else if (l.includes('1.56SF-HMC BLUCUT')) {
      return 'MONOFOCALE 1.56 SFERICA-HMC BLUCUT';
    } else if (l.includes('1.60AS-SHMC')) {
      return 'MONOFOCALE 1.60 ASFERICA-SHMC';
    } else if (l.includes('1.67AS-SHMC')) {
      return 'MONOFOCALE 1.67 ASFERICA-SHMC';
    }
  }
});
Vue.config.productionTip = false;
locale.use(lang);
var i18n = new VueI18n({
  locale: 'IT',
  //messages,
  fallbackLocale: 'EN'
});
new Vue({
  i18n: i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // @ts-ignore
    Vue.component('Canvas3d', this.$canvas3d); // @ts-ignore

    this.$rest.getCountryCode().then(function (response) {
      i18n.locale = response.data;
    }); // @ts-ignore

    this.$rest.getTranslations().then(function (response) {
      var messages = response.data;

      for (var key in messages) {
        i18n.setLocaleMessage(key, messages[key]);
      }
    }); // this.$store.commit("configurator/setLensesType", 'V');
  }
}).$mount('#app');